<template>
  <div class="mt-8">
    <!-- <p class="text-black mb-4">We are processing your WhatsApp setup.</p> -->
    <p class="text-sm text-black pb-2">
      Thank you for signing up for WhatsApp Business Account. <br />
      Your account setup is in progress, and you'll receive an email in a few minutes once it's
      ready.
    </p>
    <p class="text-sm text-black">To unlock full functionality:</p>
    <ol class="text-sm text-grey-darker">
      <li>
        <a
          href="https://www.facebook.com/business/help/2058515294227817?id=180505742745347"
          target="_blank"
          class="text-blue underline"
        >
          Verify your business
        </a>
        &nbsp;in Meta Business Manager.
      </li>
      <li>
        Ensure compliance with the
        <a href="https://business.whatsapp.com/policy" target="_blank" class="text-blue underline">
          WhatsApp Commerce Policy
        </a>
        &nbsp;to avoid restrictions.
      </li>
      <li>
        <a
          href="https://www.facebook.com/business/help/378834799515077"
          target="_blank"
          class="text-blue underline"
        >
          Submit your display name
        </a>
        &nbsp;for approval, following Meta's guidelines.
      </li>
    </ol>
  </div>
</template>
<script>
export default {
  name: 'Submit',

  props: {
    setupData: {
      type: Object,
      default: () => {},
    },
  },
};
</script>
<style lang="scss" scoped>
.box-card {
  border: solid 1px tomato;
  max-width: 500px;
}
</style>
