import moment from 'moment';
import momentDuration from 'moment-duration-format';

momentDuration(moment);

export default {
  methods: {
    // Relative to Calendar
    timeFromCalendar(datetime, offset) {
      return moment.utc(datetime).utcOffset(offset, false).calendar(null, {
        sameDay: 'h:mm A',
        lastDay: '[Yest] h A',
        nextDay: '[Tom] h A',
        lastWeek: '[Last] ddd, h A',
        nextWeek: 'dd MMM',
        sameElse() {
          return this.year() === moment.utc()
            .utcOffset(offset, false).year() ? 'D MMM' : 'D MMM YYYY';
        },
      });
    },

    // e.g. 12 Jun 2019 03:59 PM
    formatDateTime(datetime) {
      return moment.utc(datetime).format('D MMM YYYY hh:mm A');
    },

    // e.g. 12 Jun 2019
    formatDate(datetime) {
      return moment.utc(datetime).format('D MMM YYYY');
    },

    getLocalDate(date, offset, tz = '') {
      const d = moment(date).utcOffset(offset, false).format('DD MMM YYYY');

      return String(`${d} ${tz}`).trim();
    },

    getLocalDateTime(date, offset) {
      return moment.utc(date).utcOffset(offset, false).format('D MMM YYYY hh:mm A');
    },

    getLocalDateTimeTz(date, offset, tz = '', format = 'D MMM YYYY hh:mm A') {
      const d = moment.utc(date).utcOffset(offset, false).format(format);

      return String(`${d} ${tz}`).trim();
    },

    getUtcDate(date, format) {
      return moment.utc(date).format(format);
    },

    getDateHeader(tz, text) {
      return `${text || this.$t('column_labels.date')} ${tz}`;
    },
  },
};
