<template>
  <section class="card bg-white p-16 shadow relative block rounded overflow-hidden">
    <h1 class="flex items-center mb-12">
      <img src="../../assets/chat.svg" alt="Chat" class="header-logo mr-2" />
      <p class="text-2xl text-blue">
        {{ $t('sidebar_menu_parent.Chat apps') }}
      </p>
    </h1>
    <div class="card__inner">
      <h2 class="text-black text-4xl">
        {{ $t('chat_apps.default[0]') }}
      </h2>
      <p class="pt-8 pr-16 text-black text-base">
        {{ $t('chat_apps.default[1]') }}
      </p>
      <ul class="list list-reset mt-10 text-sm text-black">
        <li>{{ $t('chat_apps.default[2]') }}</li>
        <li>{{ $t('chat_apps.default[3]') }}</li>
        <li>{{ $t('chat_apps.default[4]') }}</li>
      </ul>
      <p class="text-base mt-10">
        {{ $t('chat_apps.default[5]') }}
        <a
          class="text-blue no-underline hover:underline"
          href="https://wavecell.com/chat-apps/"
          target="_blank"
        >
          {{ $t('chat_apps.default[6]') }}
        </a>
      </p>
    </div>
    <div class="pt-20">
      <a
        v-if="!allowPrepaidSignup()"
        class="no-underline text-white font-normal"
        href="mailto:cpaas-sales@8x8.com?subject=I'm interested in Chat Apps!&body=Hi 8x8, I'd
        like to learn more about integrating Chat Apps into my business."
      >
        <el-button type="primary" icon="el-icon-s-promotion" size="small">
          {{ $t('chat_apps.default[7]') }}
        </el-button>
      </a>
      <el-button
        v-else
        type="primary"
        icon="el-icon-s-promotion"
        size="small"
        @click="$router.push('/register/create-wa')"
      >
        {{ $t('chat_apps.default[8]') }}
      </el-button>
    </div>
    <img src="../../assets/dashboard-illustration.png" alt="" class="card__chat-illustration" />
  </section>
</template>

<script>
export default {
  name: 'Discover',

  props: {
    user: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      allowedCurrencies: ['USD', 'EUR', 'SGD', 'IDR', 'GBP'],
    };
  },

  methods: {
    allowPrepaidSignup() {
      const { BetaFeatures } = this.user;

      if (BetaFeatures && BetaFeatures.find((f) => f === 'CA-Prepaid')) {
        return true;
      }

      return false;
    },
  },
};
</script>

<style lang="scss" scoped>
.card {
  margin: 0 auto;
  max-width: 1000px;

  &__title {
    height: 70px;
  }

  &__inner {
    padding-right: 25em;
  }

  &__chat-illustration {
    width: 35%;
    position: absolute;
    bottom: -20px;
    right: 50px;
  }
}

.list {
  & li {
    padding-left: 10px;
    position: relative;

    &:not(:last-child) {
      margin-bottom: 15px;
    }

    &:before {
      content: '';
      width: 5px;
      height: 5px;
      background: var(--red);
      position: absolute;
      left: -5px;
      top: 6px;
      border-radius: 50%;
    }
  }
}

.header-logo {
  width: 48px;
}
</style>
