<!-- eslint-disable max-len -->
<template>
  <div class="relative pb-20">
    <h1 class="text-xl text-color font-semibold page-title mb-5 mt-5">
      {{ $t('sidebar_menu_parent.Chat apps') }}
    </h1>
    <div class="px-3 py-4 rounded bg-grey-darkest shadow flex justify-between items-center mb-10">
      <h1 class="flex text-base font-normal items-center text-white">
        {{ $t('chat_apps.dashboard.index[0]') }}
      </h1>
      <Filters
        :filters="filters"
        :loading="loading"
        :channel-list="channelsJson"
        :subaccount-list="getSubaccountsList"
        @update-filter="updateFilter"
      />
    </div>

    <!-- Messages sent -->
    <div v-loading="loading" class="flex w-full text-xs bg-white rounded">
      <SentChart
        :chart-data="getSentChartData"
        :messages-per-channel="getMessagesPerChannel"
        :channel-list="channelsJson"
        :filters="filters"
        :decimal-string-format="toDecimalString"
      />
    </div>

    <div v-loading="loading" class="mt-5">
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane
          :label="`${$t('column_labels.message_delivered')} vs ${$t('column_labels.undelivered')}`"
          name="delivered_undelivered"
        >
          <!-- Sent vs Undelivered -->
          <div class="flex w-full text-xs bg-white rounded">
            <SentUndeliveredChart
              :chart-data="getDeliveryChartData"
              :filters="filters"
              :decimal-string-format="toDecimalString"
            />
          </div>
        </el-tab-pane>
        <el-tab-pane :label="$t('chat_apps.dashboard.summary_chart[2]')" name="delivery_rate">
          <!-- Delivery rate -->
          <div class="flex w-full text-xs bg-white rounded">
            <SummaryChart
              :chart-data="{ delivery: getDeliveryRate }"
              :labels="getDeliveryRateLabels"
              :filters="filters"
              :label-format="toPercentString"
            />
          </div>
        </el-tab-pane>
        <el-tab-pane :label="$t('chat_apps.dashboard.summary_chart[3]')" name="received_messages">
          <!-- Received messages -->
          <div class="flex w-full text-xs bg-white rounded">
            <SummaryChart
              :chart-data="{ received: getReceivedMessages }"
              :labels="getReceivedMessagesLabels"
              :filters="filters"
              :label-format="toDecimalString"
            />
          </div>
        </el-tab-pane>
        <!-- <el-tab-pane :label="`${$t('column_labels.free')} vs ${$t('column_labels.charged')}`"
          name="whats_app_conversation">
          <div class="flex w-4/5 text-xs bg-white rounded">
            <SummaryChart :chart-data="getChargedAndFreeMessages"
              :filters="filters" :label-format="toDecimalString" label-size="sm" />
          </div>
        </el-tab-pane> -->
        <el-tab-pane
          v-if="
            Object.keys(getWhatsAppConversations).length &&
            Object.keys(user).length &&
            user.BetaFeatures.includes('WEB_Hide_WA_Report')
          "
          :label="$t('chat_apps.dashboard.summary_chart[4]')"
          name="whats_app_conversation"
        >
          <div class="flex w-full text-xs bg-white rounded">
            <SummaryChart
              :chart-data="getWhatsAppConversations"
              :labels="getWhatsAppConversationsLabels"
              :filters="filters"
              :label-format="toDecimalString"
              label-size="sm"
              :totals="getWAFreeAndChargeable"
            />
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
import Moment from 'moment';
import { mapGetters, mapActions, mapState, mapMutations } from 'vuex';

import channelsJson from '@/json/channels.json';
import Filters from './partials/Filters.vue';
import SentChart from './partials/SentChart.vue';
import SentUndeliveredChart from './partials/SentUndeliveredChart.vue';
import SummaryChart from './partials/SummaryChart.vue';

import { formatLocalizedNumber } from '../../utils/common';

export default {
  name: 'Dashboard',

  components: {
    Filters,
    SentChart,
    SentUndeliveredChart,
    SummaryChart,
  },

  data() {
    // const self = this;

    return {
      channelsJson,

      activeName: 'delivered_undelivered',
    };
  },

  computed: {
    ...mapGetters({
      getSubaccountsList: 'user/getSubaccountsList',
      getSentChartData: 'dashboard/getSentChartData',
      getMessagesPerChannel: 'dashboard/getMessagesPerChannel',
      getDeliveryChartData: 'dashboard/getDeliveryChartData',
      getDeliveryRate: 'dashboard/getDeliveryRate',
      getDeliveryRateLabels: 'dashboard/getDeliveryRateLabels',
      getReceivedMessages: 'dashboard/getReceivedMessages',
      getReceivedMessagesLabels: 'dashboard/getReceivedMessagesLabels',
      getChargedAndFreeMessages: 'dashboard/getChargedAndFreeMessages',
      getChargedAndFreeMessagesLabels: 'dashboard/getChargedAndFreeMessagesLabels',
      getWhatsAppConversations: 'dashboard/getWhatsAppConversations',
      getWhatsAppConversationsLabels: 'dashboard/getWhatsAppConversationsLabels',
      getWAFreeAndChargeable: 'dashboard/getWAFreeAndChargeable',
      user: 'user/getUser',
    }),

    ...mapState('user', ['timeZone', 'timeZoneOffset']),

    ...mapState({
      filters: (state) => state.dashboard.filters,
      loading: (state) => state.dashboard.loading,
    }),
  },

  created() {
    this.getUser();
    this.loadSelectOptions();
  },

  methods: {
    ...mapMutations({
      setFilters: 'dashboard/SET_FILTERS',
    }),

    ...mapActions({
      getSubaccounts: 'user/getSubaccounts',
      getDashboardData: 'dashboard/getData',
      getUser: 'user/getUser',
    }),

    // Fetch dashboard data based on filters
    getData() {
      const { daterange, ...f } = this.filters;
      // const range = this.getDaterangeValue(daterange);
      const range = { prevDays: daterange !== -1 ? daterange : '' };
      const payload = { ...f, ...range, offset: this.timeZoneOffset };

      this.getDashboardData(payload).catch((err) => {
        this.$showError(this, err);
      });
    },

    // Update filter state
    updateFilter(filter = {}) {
      this.setFilters({
        filters: {
          ...this.filters,
          ...filter,
          offset: this.timeZoneOffset,
        },
      });

      this.getData();
    },

    // Get the 'from' and 'to' value based on the subtracted based on today
    getDaterangeValue(d) {
      return {
        to: d > 0 ? Moment().endOf('day').utc().unix() : '',
        from: d > 0 ? Moment().subtract(d, 'days').startOf('day').utc().unix() : '',
      };
    },

    async loadSelectOptions() {
      try {
        await this.getSubaccounts();
        this.getData();
      } catch (err) {
        this.$showError(this, err);
        // this.$message.error('Failed to load filter options. Please try again.');
      }
    },

    // Convert 120,900 to 120.9k
    toDecimalString(n, option = {}) {
      return formatLocalizedNumber(n, option);
    },

    // Add % to the end of the number
    toPercentString(n) {
      return `${n.toFixed(n % 1 ? 2 : 0)}%`;
    },

    // Format number e.g: 1000 - 1,000
    formatNumber(n) {
      return n.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    },
  },
};
</script>

<style lang="scss" scoped>
.legend {
  height: 8px;
  width: 8px;
  background: gray;
  display: inline-block;
  border-radius: 2px;

  &--purple {
    background: #4b2586;
  }

  &--light-blue {
    background: #beedf6;
  }

  &--blue {
    background: #8a99ba;
  }

  &--dark-blue {
    background: #3320a9;
  }

  &--light-purple {
    background: #973085;
  }

  &--yellow {
    background: #fdcb3e;
  }
}

.trend {
  width: 20px;
  height: 20px;
  display: block;

  &--wa {
    fill: #25d366;
  }
  &--wc {
    fill: #9de60b;
  }
  &--fb {
    fill: #4267b2;
  }
  &--sm {
    fill: #eb526f;
  }
  &--zl {
    fill: #0071b5;
  }
  &--kk {
    fill: #f7e011;
  }
}
.chart-container {
  width: 100%;
  flex-grow: 0;
  height: 200px;
  position: relative;

  &--full {
    min-height: 300px;
    height: 100%;
  }

  &--large {
    height: 300px;
  }

  &--small {
    height: 130px;
  }
}
</style>
