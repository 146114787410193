<template>
  <div class="mb-20">
    <h1 class="text-xl text-color font-semibold page-title mb-5 mt-5">
      {{ $t('chat_apps.channels.index[0]') }}
    </h1>
    <div class="mt-5">
      <div class="mb-5">
        <h2 class="text-sm font-normal items-center">{{ $t('chat_apps.channels.index[1]') }}</h2>
        <div>
          <div
            v-if="Array.isArray(getChannelData) && getChannelData.length"
            class="mt-5 flex flex-wrap -mx-2"
          >
            <div v-for="(channel, i) in getChannelData" :key="i" class="w-1/2 xl:w-1/4 px-2 mb-4">
              <ChannelConnected :data="channel" @show-wa-update-modal="showWAESUpdateModal" />
            </div>
          </div>
          <!-- <div v-else class="text-center text-sm text-grey-dark w-full p-5">
            <i class="el-icon-loading"></i>
            {{ $t('app_labels.no_data') }}
          </div> -->
        </div>
      </div>
      <div>
        <h2 class="text-sm font-normal items-center">{{ $t('chat_apps.channels.index[2]') }}</h2>
        <div class="mt-5 flex flex-wrap -mx-2">
          <div
            v-for="channel in channelsJson
              .filter((c) => c.code !== 'SM')
              .sort((a, b) => b.supported - a.supported)"
            :key="channel.code"
            class="w-1/2 xl:w-1/4 px-2 mb-4"
          >
            <ChannelCard
              :channel-code="channel.code"
              @open-channel-form="
                () => (channel.code !== 'WA' ? handleCiFormOpen(channel.code) : selectLaunch())
              "
            />
          </div>
        </div>
      </div>
    </div>
    <ChannelForm
      v-if="selectedChannel"
      :selected-channel="selectedChannel"
      :ci-visible="ciVisible"
      :subaccount-list="getSubaccountsList"
      :submit="handleSubmitForm"
      @close="handleCloseForm"
    />

    <WAChannelSetupModal
      :show-modal="showEmbeddedSignupProgress"
      :wa-channel-to-update="waChannelToUpdate"
      :wa-form-details="waFormDetails"
      :is-update="isUpdate"
      :title="isUpdate ? 'WhatsApp Channel Update' : 'WhatsApp Channel Setup'"
      @close-modal="handleCloseWAES()"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';

import channelsJson from '@/json/channels.json';
import ChannelForm from './partials/ChannelForm.vue';
import ChannelCard from './partials/ChannelCard.vue';
import ChannelConnected from './partials/ChannelConnected.vue';
import WAChannelSetupModal from './partials/WhatsAppChannelSetupModal.vue';

export default {
  name: 'Channels',

  components: {
    ChannelForm,
    ChannelCard,
    ChannelConnected,
    WAChannelSetupModal,
  },

  data() {
    return {
      channelsJson,
      ciVisible: false,
      selectedChannel: null,
      accessToken: null,
      showEmbeddedSignupProgress: false,
      showWAESUpdate: false,
      allChannelsUsed: false,
      waChannelsUsed: 0,
      waChannelToUpdate: null,
      isUpdate: false,
      waFormDetails: null,
      waChannelDetails: {
        business: {},
        phone: {},
      },

      // loading: false,
    };
  },

  computed: {
    ...mapGetters({
      getChannelData: 'channels/getChannelData',
      getSubaccountsList: 'user/getSubaccountsList',
      user: 'user/getUser',
      caProductMeta: 'channels/getCAProductMeta',
      allSubAccounts: 'user/getSubAccounts',
    }),
  },

  async created() {
    window.fbAsyncInit = () => {
      // JavaScript SDK configuration and setup
      // eslint-disable-next-line
      FB.init({
        appId: process.env.VUE_APP_FB_WHATSAPP_APP_ID, // Facebook App ID
        cookie: true, // enable cookies
        xfbml: true, // parse social plugins on this page
        version: 'v19.0', // GAPI version
      });
    };

    // Load the JavaScript SDK asynchronously
    ((d, s, id) => {
      let fjs = d.getElementsByTagName(s)[0]; // eslint-disable-line
      if (d.getElementById(id)) return;
      const js = d.createElement(s);
      js.id = id;
      js.src = 'https://connect.facebook.net/en_US/sdk.js';
      fjs.parentNode.insertBefore(js, fjs);
    })(document, 'script', 'facebook-jssdk');
    this.loadSelectOptions();
  },

  methods: {
    ...mapActions({
      getChannels: 'channels/getChannels',
      addChannel: 'channels/addChannel',
      getSubaccounts: 'user/getSubaccounts',
      getProductMeta: 'channels/getProductMeta',
      getSharedWABAId: 'channels/getSharedWABAId',
    }),
    ...mapMutations('channels', ['SET_WABA_IDS']),

    // Open channel instance modal based on channel code
    handleCiFormOpen(code) {
      this.selectedChannel = this.channelsJson.find((c) => c.code === code && c.supported);
      this.ciVisible = true;
    },

    // Close channel instance modal
    handleCloseForm() {
      this.selectedChannel = null;
      this.ciVisible = false;
    },

    // Submit channel instance modal
    async handleSubmitForm(payload) {
      if (this.selectedChannel && this.selectedChannel.code === 'WA') {
        this.waFormDetails = payload;
        if (payload.timezone) {
          const [tz] = payload.timezone.split(' ');
          this.waFormDetails.timezone = `UTC${tz}`;
        }

        this.waChannelDetails = {
          business: {
            name: payload.accountName,
            email: payload.email,
            phone: {
              code: payload.countryCode,
              number: payload.phoneNational,
            },
            website: payload.websiteUrl,
            address: {
              streetAddress1: payload.street,
              city: payload.city,
              state: payload.state,
              zipPostal: payload.zipcode,
              country: payload.country,
            },
            timezone: this.waFormDetails.timezone || 'UTC+00',
          },
          phone: {
            displayName: payload.accountName,
            category: payload.waCategory,
            description: payload.description || '',
            code: payload.countryCode,
            number: payload.phoneNational,
          },
        };

        this.launchWhatsAppSignup();
      } else {
        try {
          await this.addChannel(payload);
        } catch (err) {
          this.$showError(this, err);
          throw new Error(err);
        }
      }
    },

    async loadSelectOptions() {
      this.loading = true;
      try {
        await this.getChannels();
        await this.getProductMeta();
        await this.getSubaccounts();

        // Check if WhatsApp Embedded Signup needs to be disabled
        this.checkAvailableWhatsAppChannels();
      } catch (err) {
        this.$showError(this, err);
        // this.$message.error(err.message);
      } finally {
        this.loading = false;
      }
    },

    checkAvailableWhatsAppChannels() {
      const caEnabledCount = this.allSubAccounts.reduce((acc, current) => {
        if (current && current.Product_CA) {
          acc = acc + 1; // eslint-disable-line
        }
        return acc;
      }, 0);

      if (!this.caProductMeta.PaidChannel || caEnabledCount > this.caProductMeta.PaidChannel) {
        this.allChannelsUsed = true;
      }
    },

    async handleCloseWAES() {
      this.showEmbeddedSignupProgress = false;
      this.showWAESUpdate = false;
      this.waChannelToUpdate = null;
      this.isUpdate = false;
      this.waFormDetails = null;

      await this.getChannels();
    },

    async getWhatsAppAccountDetails() {
      try {
        const { accessToken } = this;

        await this.getSharedWABAId({ accessToken });

        this.showEmbeddedSignupProgress = true;
      } catch (err) {
        if (window.Bugsnag) {
          const bugSnag = window.Bugsnag;

          bugSnag.notify(
            new Error('Error fetching data from Facebook after embedded signup.'),
            (event) => {
              event.severity = 'error';
              event.addMetadata('user', this.user);
            }
          );
        }

        this.$message.error('An error occurred during the WhatsApp channel setup.');
      }
    },

    selectLaunch() {
      if (!this.allChannelsUsed) {
        this.handleCiFormOpen('WA');
      } else {
        this.launchContactSales();
      }
    },

    async launchWhatsAppSignup() {
      // Conversion tracking code
      // eslint-disable-next-line
      window.fbq && window.fbq('trackCustom', 'WhatsAppOnboardingStart', {
          appId: process.env.VUE_APP_FB_WHATSAPP_APP_ID,
          feature: 'whatsapp_embedded_signup',
        });

      await this.loadSelectOptions();

      // Launch Facebook login
      FB.login(
        (response) => {
          if (response.authResponse) {
            this.accessToken = response.authResponse.accessToken;
            // Use this token to call the debug_token API and get the shared WABA's ID
            this.getWhatsAppAccountDetails();
          } else {
            if (window.Bugsnag) {
              const bugSnag = window.Bugsnag;

              bugSnag.notify(
                new Error('User cancelled WhatsApp embedded signup or did not fully authorize.'),
                (event) => {
                  event.severity = 'warning';
                  event.addMetadata('response', response);
                  event.addMetadata('user', this.user);
                }
              );
            }

            this.$message.error(
              'User cancelled WhatsApp channel setup or did not fully authorize.'
            );
          }
        },
        {
          scope: 'business_management,whatsapp_business_management',
          extras: {
            feature: 'whatsapp_embedded_signup',
            setup: {
              business: this.waChannelDetails.business,
              phone: this.waChannelDetails.phone,
            },
          },
        }
      );
    },

    launchContactSales() {
      this.$message.error(
        'No WhatsApp channel available. Please contact sales to add more channels.'
      );
    },

    showWAESUpdateModal(data) {
      this.waChannelToUpdate = data;
      this.isUpdate = true;
      this.launchWhatsAppSignup();
    },
  },
};
</script>
