import axios from 'axios';
import qs from 'query-string';

const decodeJwt = (token) => {
  try {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split('')
        .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
        .join('')
    );

    const decodedData = JSON.parse(jsonPayload);
    return decodedData;
  } catch (err) {
    console.error('Failed to validate region'); // eslint-disable-line
    return 0;
  }
};

// List of endpoints that is region based
const regionalEndpointList = [
  '/chats/logs',
  '/chats/logs/export',
  '/chats/summary',
  '/chats/reports',
  '/chats/reports/export',
  '^/files/upload-url$',
];

// Set base API URL
axios.defaults.baseURL = `${process.env.VUE_APP_API_URL}/api/v2`;

// Intercept reponse
axios.interceptors.response.use(
  (response) => {
    if (response.headers && response.headers.authorization) {
      localStorage.setItem('WWW-Authenticate', response.headers.authorization);
    }

    return response;
  },
  (err) => Promise.reject((err && err.response) || {})
);

// Intercept request to automatically add Authorization header
axios.interceptors.request.use((config) => { // eslint-disable-line
    try {
      const token = localStorage.getItem('WWW-Authenticate');

      // Check if there is no authorization headers set
      if (!config.headers.Authorization && token) {
      config.headers['Authorization'] = `Bearer ${token}`; // eslint-disable-line
      }

      const { url } = config;
      const noQueryUrl = url.split('?')[0];

      const isGeoApi = regionalEndpointList.find((apiRegExp) =>
        new RegExp(apiRegExp).test(noQueryUrl)
      );

      // Determine if endpoint is part of `regionalEndpointList`
      if (isGeoApi) {
        let regionId = '';

        // Decode jwt and determine region based on RegionId
        const decodedData = decodeJwt(token);

        if (decodedData && Object.keys(decodedData).includes('RegionId')) {
          const { RegionId: region } = decodedData;
          regionId = region;

          if (![0, 1, 2].includes(regionId)) {
            if (window.Bugsnag) {
              window.Bugsnag.notify(new Error('Region Id is invalid or undefined'), (event) => {
                event.severity = 'error';
                event.addMetadata('user', {
                  id: decodedData.UserId,
                  Email: decodedData.Email,
                  RegionId: decodedData.RegionId,
                });
              });
            }
          }
        }

        // Change baseUrl based on region
        switch (regionId) {
          // Indonesia region
          case 1:
          config.baseURL = `${process.env.VUE_APP_API_URL_ID}/api/v2`; // eslint-disable-line
            break;

          // UK region
          case 2:
          config.baseURL = `${process.env.VUE_APP_API_URL_UK}/api/v2`; // eslint-disable-line
            break;

          // Default Singapore region
          default:
            break;
        }
      }

      return config;
    } catch (err) {
      if (window.Bugsnag) {
        window.Bugsnag.notify(err);
      }
    }
  },
  (err) => Promise.reject(err)
);

/**
 * Requests a URL, returning a promise.
 *
 * @param  {string} url       The URL we want to request
 * @param  {object} [options] The options we want to pass to "fetch"
 * @return {object}           An object containing either "data" or "err"
 */
const request = (options) => axios(options);

const get = (url, params = {}) =>
  axios.get(url, {
    params: {
      ...params,
    },
    paramsSerializer(p) {
      return qs.stringify(p, { indices: false });
    },
  });

const post = (url, data = {}, config = {}) => axios.post(url, data, config);

const put = (url, data = {}) => axios({ method: 'put', url, data });

const del = (url, data = {}) => axios({ method: 'delete', url, data });

export default {
  request,
  get,
  post,
  put,
  del,
};
