<template>
  <div>
    <div class="channel-connected bg-white shadow w-full block text-center rounded overflow-hidden
      h-full"
      :style="{height: '280px'}"
      :class="[{'channel-card': data.type === 'WhatsApp' }]"
      @click="selectLaunch(data)">
      <div
        class="channel-connected__bg shadow-inner"
        :style="{'background-image': `url('${getBgPattern(data.name)}')`}"
      >
      </div>
      <div class="channel-connected__info">
        <div class="channel-connected__icon">
          <img
            :src="getIcon(data.type)"
            width="25"
            height="25px"
            alt="channel-icon"
          >
        </div>
        <div class="p-3">
          <div class="mb-5">
            <p class="text-xs text-black">{{ data.type }}</p>
            <p class="text-xs text-green mt-1">{{ $t('column_labels.connected') }}</p>
          </div>
          <div>
            <p class="text-sm text-black">{{ data.name }}</p>
            <p v-if="data.phoneNumber" class="text-xs text-grey mt-2">
              {{ formatPhoneNumber(data.phoneNumber) }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { parsePhoneNumber } from 'awesome-phonenumber';

import channelsJson from '@/json/channels.json';

import whatsAppIcon from '@/assets/channels/whatsapp.svg';
import weChatIcon from '@/assets/channels/wechat.svg';
import facebookIcon from '@/assets/channels/facebook.svg';
import googleRcsIcon from '@/assets/channels/googlercs.svg';
import messengerIcon from '@/assets/channels/appleimessage.svg';
import lineIcon from '@/assets/channels/line.svg';
import viberIcon from '@/assets/channels/viber.svg';
import zaloIcon from '@/assets/channels/zalo.svg';
import smsIcon from '@/assets/channels/sms.svg';
import kakaoIcon from '@/assets/channels/kakao.svg';

import bg1 from '@/assets/tiles/tile1.svg';
import bg2 from '@/assets/tiles/tile2.svg';
import bg3 from '@/assets/tiles/tile3.svg';
import bg4 from '@/assets/tiles/tile4.svg';
import bg5 from '@/assets/tiles/tile5.svg';
import bg6 from '@/assets/tiles/tile6.svg';
import bg7 from '@/assets/tiles/tile7.svg';
import bg8 from '@/assets/tiles/tile8.svg';
import bg9 from '@/assets/tiles/tile9.svg';
import bg10 from '@/assets/tiles/tile10.svg';
import bg11 from '@/assets/tiles/tile11.svg';

export default {
  name: 'channel-connected',

  data() {
    const bgList = [bg1, bg2, bg3, bg4, bg5, bg6, bg7, bg8, bg9, bg10, bg11];
    return {
      bgList,
      channelsJson,
    };
  },

  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },

  methods: {
    formatPhoneNumber(n) {
      const s = n.match(/^[+][0-9]/) ? n : `+${n}`;
      const pn = parsePhoneNumber(s);
      return pn.number.international;
    },

    getIcon(code) {
      let icon = smsIcon;
      switch (code.trim().toLowerCase()) {
        case 'whatsapp':
          icon = whatsAppIcon;
          break;
        case 'wechat':
          icon = weChatIcon;
          break;
        case 'facebook':
          icon = facebookIcon;
          break;
        case 'googlercs':
          icon = googleRcsIcon;
          break;
        case 'appleimessage':
          icon = messengerIcon;
          break;
        case 'line':
          icon = lineIcon;
          break;
        case 'viber':
          icon = viberIcon;
          break;
        case 'zalo':
          icon = zaloIcon;
          break;
        case 'kakao':
          icon = kakaoIcon;
          break;
        default:
          break;
      }
      return `${process.env.VUE_APP_BASE_URL.replace(/\/$/g, '')}${icon}`;
    },

    // Pseudo seed randomize pattern based on name
    getBgPattern(n) {
      const seedRng = Math.floor(((Math.sin(n.length) * 10000)
       - Math.floor(Math.sin(n.length) * 10000)) * this.bgList.length);
      return `${process.env.VUE_APP_BASE_URL.replace(/\/$/g, '')}${this.bgList[seedRng]}`;
    },

    getChannelName(code) {
      return this.channelsJson.find(c => c.code === code).name;
    },

    selectLaunch(data) {
      if ((data.type !== 'WhatsApp')
        || (data.type === 'WhatsApp'
        && !data.whatsAppBusinessAccountId)) return;

      this.$emit('show-wa-update-modal', data);
    },
  },
};
</script>

<style lang="scss" scoped>
.channel-connected {
  &__bg {
    padding-top: 50%;
    background-color: whitesmoke;
    background-position: center;
    background-size: cover;
  }

  &__icon {
    margin: -27px auto 0;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background: white;
  }

  &__info {
    border-top: solid 3px #38C172;
  }
}

.channel-card {
  cursor: pointer;
  transition: all 0.05s ease-in;
  border: solid 1px transparent;

  &:hover {
    border-color: #404447;
    transform: scale(1.03);
    transform-origin: center;
  }

  &__disabled {
    cursor: default;

    &:hover {
      border-color: #DAE1E7;
      opacity: 0.7;
      transform: none;
      filter: grayscale(100%);
    }
  }
}
</style>
