<template>
  <div class="whatsapp-form">
    <el-form
      ref="contactInfoForm"
      :model="contactInfoForm"
      :rules="contactInfoFormRules"
      class="pt-4"
      label-position="top"
    >
      <el-form-item prop="email" :label="$t('chat_apps.channels.whatsapp[3]')">
        <el-input
          v-model="contactInfoForm.email"
          placeholder="Ex. contact@my-company.com"
          size="small"
        />
      </el-form-item>

      <el-form-item prop="websiteUrl" :label="$t('column_labels.website_url')">
        <el-input
          v-model="contactInfoForm.websiteUrl"
          placeholder="https://www.my-company.com"
          size="small"
        />
      </el-form-item>

      <el-form-item prop="avatarUrl" :label="$t('column_labels.profile_pic')">
        <el-input
          v-model="contactInfoForm.avatarUrl"
          placeholder="https://www.my-company/avatar.png"
          size="small"
        />
      </el-form-item>

      <el-form-item prop="street" :label="$t('column_labels.business_address')">
        <el-input
          v-model="contactInfoForm.street"
          :placeholder="$t('fields.street')"
          size="small"
          maxlength="40"
          show-word-limit
        />
      </el-form-item>

      <el-form-item prop="city">
        <el-input
          v-model="contactInfoForm.city"
          :placeholder="$t('fields.city')"
          size="small"
          maxlength="30"
          show-word-limit
        />
      </el-form-item>

      <el-form-item prop="state">
        <el-input
          v-model="contactInfoForm.state"
          :placeholder="$t('fields.state')"
          size="small"
          maxlength="30"
          show-word-limit
        />
      </el-form-item>

      <div class="flex">
        <div class="w-3/5 mr-5">
          <el-form-item prop="country">
            <el-select
              v-model="contactInfoForm.country"
              :placeholder="$t('fields.country')"
              class="w-full"
              size="small"
              filterable
            >
              <el-option
                v-for="country in countriesJson"
                :key="country.value"
                :label="country.name"
                :value="country.value"
              ></el-option>
            </el-select>
          </el-form-item>
        </div>

        <div class="w-2/5">
          <el-form-item prop="zipcode">
            <el-input
              v-model="contactInfoForm.zipcode"
              :placeholder="$t('fields.zip')"
              size="small"
              step="0"
            />
          </el-form-item>
        </div>
      </div>

      <el-form-item prop="timezone" :label="$t('date_labels.timezone')">
        <el-select
          v-model="contactInfoForm.timezone"
          :placeholder="$t('date_labels.pick_time_zone')"
          filterable
          size="small"
        >
          <el-option
            v-for="tz in timeZones"
            :key="tz.name"
            :label="`${tz.utcOffsetStr} (${tz.name})`"
            :value="`${tz.utcOffsetStr} (${tz.name})`"
          >
          </el-option>
        </el-select>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import { mapActions } from 'vuex';
import countriesJson from '@/json/countries.json';
import ct from 'countries-and-timezones';

export default {
  name: 'ContactInformation',

  props: {
    setupData: {
      type: Object,
      default: () => {},
    },

    update: {
      type: Function,
      default: () => {},
    },
  },

  data() {
    const checkImage = async (rule, value, callback) => {
      const isValid = await new Promise((resolve) => {
        const img = new Image();

        img.src = value;

        img.onload = () => resolve(true);
        img.onerror = () => resolve(false);
      });

      if (isValid) callback();
      else {
        this.isLoading = false;
        callback(new Error('Image does not exist'));
      }
    };
    const self = this;

    return {
      validPhone: false,
      countriesJson,
      timeZones: null,

      contactInfoForm: {
        email: '',
        websiteUrl: '',
        avatarUrl: '',
        street: '',
        city: '',
        state: '',
        country: '',
        zipcode: '',
        timezone: 'UTC+00 (UTC)',
      },

      contactInfoFormRules: {
        email: [
          {
            required: true,
            message: self.$t('validations.required', { value: self.$t('fields.email') }),
            trigger: 'blur',
          },
          {
            type: 'email',
            message: self.$t('validations.valid', { value: self.$t('fields.email') }),
            trigger: ['blur', 'change'],
          },
        ],

        websiteUrl: [
          {
            required: true,
            message: self.$t('validations.required', {
              value: self.$t('column_labels.website_url'),
            }),
            trigger: 'blur',
          },
          {
            pattern:
              /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)$/,
            message: self.$t('validations.valid', { value: self.$t('column_labels.website_url') }),
            trigger: 'blur',
          },
        ],

        avatarUrl: [
          {
            required: true,
            message: self.$t('validations.required', { value: self.$t('fields.avatar_url') }),
            trigger: 'blur',
          },
          {
            pattern: /(?:([^:/?#]+):)?(?:\/\/([^/?#]*))?([^?#]*\.(?:jpe?g|png)$)/,
            message: self.$t('validations.valid', { value: self.$t('fields.avatar_url') }),
            trigger: 'blur',
          },
          {
            validator: checkImage,
            message: self.$t('validations.image_does_not_exist'),
            trigger: 'blur',
          },
        ],

        street: [
          {
            required: true,
            message: self.$t('validations.required', {
              value: self.$t('fields.street'),
            }),
            trigger: 'blur',
          },
        ],

        city: [
          {
            required: true,
            message: self.$t('validations.required', {
              value: self.$t('fields.city'),
            }),
            trigger: 'blur',
          },
        ],

        state: [
          {
            required: true,
            message: self.$t('validations.required', {
              value: self.$t('fields.state'),
            }),
            trigger: 'blur',
          },
        ],

        address1: [
          {
            required: true,
            message: self.$t('validations.required', {
              value: self.$t('fields.address_line', { no: '1' }),
            }),
            trigger: 'blur',
          },
        ],

        address2: [
          {
            required: true,
            message: self.$t('validations.required', {
              value: self.$t('fields.address_line', { no: '2' }),
            }),
            trigger: 'blur',
          },
        ],

        country: [
          {
            required: true,
            message: self.$t('validations.required', { value: self.$t('fields.country') }),
            trigger: 'blur',
          },
        ],

        zipcode: [
          {
            required: true,
            message: self.$t('validations.required', { value: self.$t('fields.zip') }),
            trigger: 'blur',
          },
        ],

        timezone: [
          {
            required: true,
            message: self.$t('validations.required', { value: self.$t('date_labels.timezone') }),
            trigger: 'blur',
          },
        ],
      },
    };
  },

  watch: {
    contactInfoForm: {
      deep: true,
      handler() {
        const { email, websiteUrl, avatarUrl, street, city, state, country, zipcode, timezone } =
          this.contactInfoForm;

        if (
          !!email &&
          !!websiteUrl &&
          !!avatarUrl &&
          !!street &&
          !!city &&
          !!state &&
          !!country &&
          !!zipcode &&
          !!timezone
        ) {
          this.$refs.contactInfoForm.validate((valid) => {
            if (valid) {
              this.submit({ validated: true });
            }

            return false;
          });
        } else {
          this.submit({ validated: false });
        }
      },
    },
  },

  created() {
    const allTz = ct.getAllTimezones();

    if (!this.timeZones) {
      this.timeZones = Object.values(allTz).sort((a, b) => a.utcOffset - b.utcOffset);
    }
  },

  methods: {
    submit(validated) {
      this.$emit('update', {
        contactInfo: {
          ...this.contactInfoForm,
          ...validated,
        },
      });
    },
  },
};
</script>

<styles lang="scss" scoped>
.whatsapp-form {
  max-width: 400px;

  .el-form-item.is-error {
    .whatsapp-form__tel-input {
      border: solid 1px tomato !important;
    }
  }
  .el-form-item.is-success {
    .whatsapp-form__tel-input {
      border: solid 1px #67C23A !important;
    }
  }
  .el-form-item__label {
    line-height: 0;
    margin: 20px 0 10px;
  }

  .el-form--label-top .el-form-item__label {
    padding: 0px;
  }

  .note {
    word-break: break-word;
  }
  &__tel-input {
    margin-top: 10px;
    height: 32px;
    border-radius: 4px !important;
    border: 1px solid #DCDFE6 !important;
    box-shadow: none !important;

    .vti__selection {
      height: 30px;
    }

    input {
      font-size: 13px;
      color: #606266;
      border-radius: 4px !important;

      &::placeholder {
        color: #E0E2E5;
        opacity: 1;
      }
    }
  }
}
</styles>
