/* eslint-disable no-param-reassign */
import actions from './actions';

export default {
  namespaced: true,

  state: {
    data: {},
    loading: true,
    selectedWabaId: null,
    wabaDetails: null,
    productMeta: {},
    caSystemUsers: [],
    phoneDetails: null,
    creditLine: null,
  },

  getters: {
    getChannelData: (st) => st.data,
    getCAProductMeta: (st) => st.productMeta,
  },

  mutations: {
    SET_DATA: (st, payload) => {
      st.data = payload.data;
    },
    SET_SERVICE_PURCHASED: (st, payload) => {
      st.productMeta = payload.data;
    },
    SET_SELECTED_WABA_ID: (st, payload) => {
      st.selectedWabaId = payload.data;
    },
    SET_ADMIN_USERS: (st, payload) => {
      st.caSystemUsers = payload.data;
    },
    SET_PHONE_DETAILS: (st, payload) => {
      st.phoneDetails = payload.data;
    },
    SET_CREDIT_LINE: (st, payload) => {
      st.creditLine = payload.data;
    },
    SET_WABA_DETAILS: (st, payload) => {
      st.wabaDetails = payload.data;
    },
    RESET_STATE: (st) => {
      const s = {
        data: {},
        loading: true,
        selectedWabaId: null,
        wabaDetails: null,
        productMeta: {},
        caSystemUsers: [],
        phoneDetails: null,
        creditLine: null,
      };

      st = {...s}; // eslint-disable-line

      return st;
    },
  },

  actions,
};
