import Vue from 'vue'; // eslint-disable-line
import VueRouter from 'vue-router';
import ElementUI from 'element-ui';
import VueSanitize from 'vue-sanitize';
import VueTelInput from 'vue-tel-input';
import singleSpaVue from 'single-spa-vue';

import * as VueGoogleMaps from 'vue2-google-maps';

// import locale from 'element-ui/lib/locale/lang/en';
import VueI18n from 'vue-i18n';
import App from './App.vue';
import routes from './router';
import store from './store';

if (window.Bugsnag) {
  window.Bugsnag.getPlugin('vue').installVueErrorHandler(Vue);
}

Vue.use(VueI18n);

Vue.use(VueGoogleMaps, {
  load: {
    key: `${process.env.VUE_APP_GOOGLE_MAPS_KEY}`,
  },
});

const i18n = new VueI18n({
  locale: localStorage.getItem('SELECTED_LOCALE') || 'en', // set locale
  fallbackLocale: 'en',
});

// Vue.use(ElementUI, { locale });
Vue.use(VueRouter);
Vue.use(VueTelInput);

const sOptions = {
  allowedTags: ['b', 'strong', 'p', 's', 'em', 'br', 'del', 'strike', 'i', 'mark'],
};

Vue.use(VueSanitize, sOptions);

const router = new VueRouter({ // eslint-disable-line
  base: '/chat',
  mode: 'history',
  routes,
});

router.beforeEach((to, from, next) => {
  // Prevent rerouting if app is unmounted
  if (!window.location.pathname.match(/^\/chat/i)) {
    return 0;
  }

  next();
  return 0;
});

// eslint-disable-next-line
__webpack_public_path__ = process.env.VUE_APP_BASE_URL

const vueLifecycles = singleSpaVue({
  Vue,
  appOptions: {
    el: '#chat',
    i18n,
    router,
    store,
    render(h) {
      // check root config for locale messages
      Object.keys(this.localeMessages).forEach((v) => {
        this.$i18n.setLocaleMessage(v, this.localeMessages[v]);
      });

      Vue.use(ElementUI, {
        i18n: (key, value) => this.$i18n.t(key, value),
      });

      const { localeMessages, langUtil, appConfig, telemetry } = this;

      Vue.prototype.$telemetry = telemetry;

      Vue.prototype.appConfig = appConfig;

      return h(App, { props: { localeMessages, langUtil, appConfig, telemetry } });
    },
    setPublicPath(path) {
      // eslint-disable-next-line
      __webpack_public_path__ = path
    },
  },
});

export const bootstrap = [vueLifecycles.bootstrap];

export const mount = [vueLifecycles.mount];

export const unmount = [vueLifecycles.unmount];
