<template>
  <el-dialog
    :visible.sync="showModal"
    :show-close="false"
    destroy-on-close
    center
    width="70%"
    @open="activeStep = 0"
    @close="closeModal"
  >
    <div class="channel-setup">
      <el-row>
        <el-col :span="24">
          <div class="flex justify-between items-center mb-12 sticky pin-t bg-white z-50">
            <h1 class="text-xl text-black font-normal pl-8">
              {{ title }}
            </h1>
            <i
              class="mr-12 text-xl el-icon-close cursor-pointer hover:text-blue"
              @click="closeModal"
            ></i>
          </div>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <el-steps :active="activeStep" finish-status="success" align-center>
            <el-step
              :title="$t('chat_apps.channels.waes.step', { num: 1 })"
              :description="$t('webhooks.modal[1]')"
            />
            <el-step
              :title="$t('chat_apps.channels.waes.step', { num: 2 })"
              description="Save details"
            />
            <el-step
              :title="$t('chat_apps.channels.waes.step', { num: 3 })"
              :description="$t('chat_apps.channels.waes.done')"
            />
          </el-steps>
        </el-col>
      </el-row>
      <el-row v-if="activeStep === 0" v-loading="loading" class="waes-progress pt-4">
        <el-col :span="10" :offset="2" class="pt-4">
          <p class="pb-2">
            <strong>{{ $t('chat_apps.channels.waes.select_phone') }}</strong>
          </p>
          <el-select
            v-model="selectedPhoneNumberId"
            :placeholder="$t('chat_apps.channels.waes.select_phone')"
          >
            <el-option
              v-for="p in phoneDetails"
              :key="p.id"
              :label="getPhoneLabel(p)"
              :value="p.id"
            >
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="10" :offset="2" class="pt-4">
          <p class="pb-2">
            <strong>{{ $t('chat_apps.channels.waes.select_sa') }}</strong>
          </p>
          <el-select
            v-model="selectedSubAccount"
            :placeholder="$t('chat_apps.channels.waes.select_sa')"
          >
            <el-option
              v-for="sa in getValidSubAccounts()"
              :key="sa.SubAccountUid"
              :label="sa.SubAccountId"
              :value="sa.SubAccountUid"
              :disabled="!sa.Product_CA"
            >
            </el-option>
          </el-select>
        </el-col>
        <el-col v-if="getValidSubAccounts().length === 0" :span="24" class="mt-8 pt-12">
          <p class="text-red">
            <center>
              {{ $t('chat_apps.channels.waes.warning[4]') }}
            </center>
          </p>
        </el-col>
        <el-col v-if="isBounded && !isUpdate" :span="24" class="mt-8 pt-12">
          <p class="text-red">
            <center>
              {{ $t('chat_apps.channels.waes.warning[0]') }},
              {{ $t('chat_apps.channels.waes.warning[1]') }}
              <br />{{ $t('chat_apps.channels.waes.warning[2]') }}
            </center>
          </p>
        </el-col>
        <el-col v-if="isUpdate" :span="24" class="pt-12 mt-8">
          <p class="text-red">
            <center>
              <span v-if="isBounded"> {{ $t('chat_apps.channels.waes.warning[0]') }}. </span>
              {{ $t('chat_apps.channels.waes.warning[3]') }}
              <br />{{ $t('chat_apps.channels.waes.warning[2]') }}
            </center>
          </p>
        </el-col>
        <el-col v-if="!isBounded && !isUpdate" :span="24" class="mt-8 pt-12">
          <p class="text-danger">&nbsp;</p>
        </el-col>
        <el-col :span="2" :offset="2" class="mt-4 pt-4">
          <el-button
            type="primary"
            class="mt-2"
            :disabled="!selectedPhoneNumber || !selectedSubAccount"
            @click="processWABA()"
          >
            {{ $t('actions.next') }}
          </el-button>
        </el-col>
      </el-row>
      <el-row v-if="activeStep === 1" class="waes-progress pt-4">
        <el-col :span="24" class="pt-4">
          <center class="text-grey">
            {{ $t('chat_apps.channels.waes.save_db') }}
          </center>
          <el-col :span="24" class="pb-1 pt-4">
            <center>
              <p>
                {{ $t('chat_apps.channels.waes.display_name') }}:&nbsp;
                <span
                  ><strong>{{ savedDetails.name }}</strong></span
                >
              </p>
            </center>
          </el-col>
          <el-col :span="24" class="py-2">
            <center>
              <p>
                {{ $t('chat_apps.channels.waes.bounded_sa') }}:&nbsp;
                <span>
                  <strong>
                    {{ savedDetails.boundedSubAccount }}
                  </strong>
                </span>
              </p>
            </center>
          </el-col>
          <el-col :span="24" class="py-2">
            <center>
              <p>
                {{ $t('column_labels.phone_no') }}:&nbsp;
                <span
                  ><strong>{{ savedDetails.phoneNumber }}</strong></span
                >
              </p>
            </center>
          </el-col>
        </el-col>
        <el-col :span="24" class="mt-6 mb-4">
          <center>
            <p v-if="retry" class="text-red pb-1">
              {{ $t('chat_apps.channels.waes.back_warning') }}
            </p>
            <p v-if="retryMessage" class="text-red">
              <i>{{ retryMessage }}</i>
            </p>
          </center>
        </el-col>
        <el-col :span="22" :offset="2" class="mt-2 pt-4">
          <el-button type="primary" class="mt-2 mr-2" @click="activeStep = 0">
            {{ $t('actions.back') }}
          </el-button>
          <el-button
            type="primary"
            class="mt-2"
            :disabled="loading || retry"
            @click="activeStep = 2"
          >
            {{ $t('actions.next') }}
          </el-button>
        </el-col>
        <el-col v-if="loading" v-loading="loading" :span="24" class="my-8"> &nbsp; </el-col>
      </el-row>
      <el-row v-if="activeStep === 2" class="waes-progress pt-4">
        <el-col :span="24" class="pt-4">
          <center class="text-grey">
            {{ $t('chat_apps.channels.waes.thank_you') }}
          </center>
          <br />
          <p class="py-2">
            Please make sure that you verify your business with Facebook in order to access the full
            experience, here is the process to do so:
            <a href="https://www.facebook.com/business/help/2058515294227817">
              https://www.facebook.com/business/help/2058515294227817
            </a>
          </p>
        </el-col>
        <el-col v-if="!loading" v-loading="loading" :span="24" class="my-8">
          <el-button type="primary" class="mt-2" @click="closeModal">
            {{ $t('actions.close') }}
          </el-button>
        </el-col>
      </el-row>
    </div>
  </el-dialog>
</template>

<script>
import { mapActions, mapState, mapGetters, mapMutations } from 'vuex';
import Async from 'async';

export default {
  props: {
    showModal: {
      type: Boolean,
      default: false,
    },

    title: {
      type: String,
      default: 'WhatsApp Channel Setup',
    },

    waChannelToUpdate: {
      type: Object,
      default: () => {},
    },

    waFormDetails: {
      type: Object,
      default: () => {},
    },

    isUpdate: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      activeStep: null,
      selectedSubAccount: null,
      selectedPhoneNumber: null,
      selectedPhoneNumberId: null,
      savedDetails: null,
      loading: false,
      retry: false,
      isBounded: false,
      retryMessage: null,
    };
  },

  computed: {
    ...mapState('channels', [
      'caSystemUsers',
      'productMeta',
      'phoneDetails',
      'creditLine',
      'wabaDetails',
    ]),
    ...mapState('user', ['subaccounts']),
    ...mapGetters({
      user: 'user/getUser',
      getChannelData: 'channels/getChannelData',
    }),
  },

  watch: {
    activeStep: {
      immediate: true,
      handler() {
        if (this.activeStep === 1) {
          this.saveData();
        }
      },
    },

    selectedPhoneNumberId: {
      handler(val) {
        if (val) {
          const phone = this.phoneDetails.find((p) => p.id === this.selectedPhoneNumberId);

          this.SET_SELECTED_WABA_ID({ data: phone.wabaId });
          this.selectedPhoneNumber = phone.phoneNumber || null;
        }
      },
    },

    selectedSubAccount: {
      handler() {
        const subAccountName = this.getSubAccountName(this.selectedSubAccount);
        const channel = this.getChannelData.find(c => c.type === 'WhatsApp' && c.boundedSubAccount === subAccountName && c.status === 'Active'); // eslint-disable-line

        if (channel) {
          this.isBounded = true;
          if (
            this.waChannelToUpdate &&
            channel.boundedSubAccount === this.waChannelToUpdate.boundedSubAccount
          ) {
            this.isBounded = false;
          }
        } else {
          this.isBounded = false;
        }
      },
    },

    waChannelToUpdate: {
      deep: true,
      immediate: true,
      handler(val) {
        if (val && val.whatsAppBusinessAccountId && val.id && val.boundedSubAccount) {
          const sa = this.subaccounts.find((s) => s.SubAccountId === val.boundedSubAccount);
          if (sa) this.selectedSubAccount = sa.SubAccountUid;

          if (this.phoneDetails) {
            const phone = this.phoneDetails.find((p) => p.phoneNumber === val.phoneNumber);
            if (phone) {
              // phoneNumber and wabaId will be set in above watch
              this.selectedPhoneNumberId = phone.id;
            }
          }
        }
      },
    },
  },

  methods: {
    ...mapActions({
      processIntegration: 'channels/processIntegration',
      saveWAESDetails: 'channels/saveWAESDetails',
    }),
    ...mapMutations('channels', ['SET_SELECTED_WABA_ID']),

    showErrorMessage(msg, details) {
      if (window.Bugsnag) {
        const bugSnag = window.Bugsnag;

        bugSnag.notify(new Error(msg), (event) => {
          event.severity = 'error'; // eslint-disable-line
          event.addMetadata('details', details); // eslint-disable-line
          event.addMetadata('user', this.user); // eslint-disable-line
          event.addMetadata('formDetails', this.waFormDetails); // eslint-disable-line
        });
      }

      this.$message.error(this.$t('chat_apps.channels.waes.set_account_error'));
      console.log('An error occurred during the WhatsApp channel setup.'); // eslint-disable-line
    },

    closeModal() {
      this.$emit('close-modal');

      this.activeStep = 0;
      this.loading = false;
      this.selectedSubAccount = null;
      this.savedDetails = null;
      this.selectedPhoneNumberId = null;
      this.selectedPhoneNumber = null;
      this.retry = false;
      this.retryMessage = null;
    },

    async processWABA() {
      this.loading = true;
      this.retry = false;

      try {
        // Call API here
        await this.processIntegration();

        this.activeStep = 1;
      } catch (err) {
        let msg = err.message || '';
        const details = {
          wabaIds: this.wabaIds,
          selectedWabaId: this.selectedWabaId,
          productMeta: this.productMeta,
        };

        if (err && err.error && err.error.message) {
          msg = err.error.message;
        }

        this.showErrorMessage('Error processing WABA ID', details);
        this.retry = true;
        this.retryMessage = msg;
      } finally {
        this.loading = false;
      }
    },

    async saveData() {
      this.loading = true;
      this.retry = false;
      const phone = this.phoneDetails.find((p) => p.id === this.selectedPhoneNumberId);
      const subAccountName = this.getSubAccountName(this.selectedSubAccount);

      let params = {
        type: 'WhatsApp',
        name: phone.verifiedName,
        comment: `${phone.verifiedName} WA Channel created from Connect`,
        whatsappBusinessAccountId: phone.wabaId,
        whatsappTemplateNamespace: phone.id,
        boundedSubAccount: subAccountName,
        phoneNumber: phone.phoneNumber,
        certificate: phone.certificate,
        subAccountId: subAccountName, // this.selectedSubAccount,
        isUpdate: this.isUpdate,
        id: this.waChannelToUpdate ? this.waChannelToUpdate.id : null,
        userId: this.user.UserId,
        accountId: this.user.AccountId,
      };

      if (this.waFormDetails) {
        const {
          name, // eslint-disable-line
          accountName,
          email,
          street,
          city,
          state,
          country,
          zipcode,
          tagline: about,
          description,
          category,
          waCategory,
          websiteUrl: accountUrl,
          avatarUrl: iconUrl,
        } = this.waFormDetails;
        const address = `${street} ${city}, ${state}, ${country} ${zipcode}`;
        params = {
          ...params,
          accountName,
          about,
          description,
          accountUrl,
          iconUrl,
          email,
          category,
          waCategory,
          address: address.trim(),
        };
      }

      if (this.isUpdate) {
        params = {
          ...this.waChannelToUpdate,
          ...params,
        };
      }

      this.savedDetails = params;
      this.loading = false;

      try {
        await this.saveWAESDetails(params);
      } catch (error) {
        if (error) {
          this.retry = true;
          this.retryMessage = error.error || '';
        }
      }
    },

    getSubAccountName(id) {
      const sa = this.subaccounts.find((s) => s.SubAccountUid === id);

      return sa ? sa.SubAccountId : '';
    },

    getPhoneLabel(p) {
      return `${p.verifiedName} (${p.phoneNumber})`;
    },

    getLabel(sa) {
      const channel = this.getChannelData.find(c => c.type === 'WhatsApp' && c.boundedSubAccount === sa.SubAccountId && c.status === 'Active'); // eslint-disable-line

      return channel ? `${sa.SubAccountId} (Bounded)` : sa.SubAccountId;
    },

    getValidSubAccounts() {
      const getLinkedChannel = (subAccountId) => {
        return this.getChannelData.find(c => c.type === 'WhatsApp' && c.boundedSubAccount === subAccountId && c.status === 'Active'); // eslint-disable-line
      };

      const validSubAccounts = this.subaccounts.filter(
        (s) => s.Product_CA && !getLinkedChannel(s.SubAccountId)
      );

      return validSubAccounts;
    },
  },
};
</script>

<style lang="scss" scoped>
.waes-progress {
  height: 280px;
}

.channel-setup {
  margin: 0 auto;
  width: 100%;
  max-width: 800px;
}
</style>
