<template>
  <div>
    <div class="mb-20 flex flex-col">
      <h1 class="text-xl text-color font-semibold page-title f mb-10">
        {{ $t('sidebar_menu_children.Reports') }}
      </h1>
      <el-row :gutter="20">
        <el-col :span="18">
          <span class="text-sm text-grey mb-10 block">
            {{ $t('chat_apps.reports.index[0]') }}
          </span>
          <Summary
            v-loading="loading"
            :data="getMessagesData"
            :filters="filters"
            :channels="getMessagesPerType"
            :chargables="
              metricSubOptions.whatsappConversations.filter((o) => !o.value.match(/all/i))
            "
            @update-filter="updateFilter"
            @update-checkbox-filter="updateChannelFilter"
            @update-chargable-filter="updateChargableFilter"
          />
          <Table
            class="mt-10"
            :loading="loading"
            :data="getReportsTable"
            :channels="getMessagesPerType"
            :filters="filters"
          />
        </el-col>
        <el-col :span="6">
          <div class="pt-20 -mt-20">
            <Filters
              :filters="filters"
              :default-daterange="defaultDaterange"
              :report-type-options="reportTypeOptions"
              :metric-options="metricOptions"
              @update-filter="updateFilter"
            />
            <div class="mt-5 shadow p-5 rounded flex flex-col justify-center">
              <p class="text-sm text-black text-center">
                {{ $t('chat_apps.reports.index[1]') }}
              </p>
              <el-button
                class="mt-2"
                size="small"
                type="primary"
                @click="displayExportReports(true)"
              >
                {{ $t('actions.export') }}
              </el-button>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
    <ExportModal
      :title="$t('chat_apps.reports.index[4]')"
      :filters="filters"
      :is-visible="showExportModal"
      :report-type-list="reportTypeOptions"
      :metric-list="metricSubOptions"
      @submit="getDownloadReports"
      @close-modal="displayExportReports(false)"
    />
  </div>
</template>

<script>
import Moment from 'moment';
import { mapState, mapActions, mapMutations, mapGetters } from 'vuex';

import channelOptions from '@/json/channels.json';
import Summary from './partials/Summary.vue';
import Table from './partials/Table.vue';
import ExportModal from './partials/ExportModal.vue';
import Filters from './partials/Filters.vue';

export default {
  name: 'Reports',

  components: {
    Summary,
    Table,
    ExportModal,
    Filters,
  },

  data() {
    const reportTypeOptions = [
      { name: this.$t('column_labels.by_channel'), value: 'channel_wise' },
      { name: this.$t('chat_apps.dashboard.summary_chart[4]'), value: 'whatsapp_conversations' },
    ];

    const metricSubOptions = {
      byChannel: [
        { name: this.$t('column_labels.outgoing'), value: 'outgoing' },
        { name: this.$t('column_labels.incoming'), value: 'incoming' },
      ],
      whatsappConversations: [
        { name: this.$t('column_labels.all'), value: 'all' },
        // { name: this.$t('column_labels.free'), value: 'free' },
        // { name: this.$t('column_labels.chargeable'), value: 'chargeable' },
        { name: this.$t('chat_apps.categories.Authentication'), value: 'authentication' },
        { name: this.$t('chat_apps.categories.Marketing'), value: 'marketing' },
        { name: this.$t('chat_apps.categories.Utility'), value: 'utility' },
        { name: this.$t('column_labels.service'), value: 'service' },
      ],
    };

    return {
      defaultDaterange: [
        Number(Moment().startOf('day').subtract(1, 'w')),
        Number(Moment().endOf('day')),
      ],

      showExportModal: false,

      reportTypeOptions,

      metricSubOptions,

      metricOptions: [],

      channelOptions,

      daterangeOptions: [
        { name: this.$t('date_labels.today'), value: 1 }, // Today
        { name: this.$t('date_labels.last_7_days'), value: 7 }, // Last 7 days
        { name: this.$t('date_labels.last_14_days'), value: 14 }, // Last 14 days
        { name: this.$t('date_labels.last_30_days'), value: 30 }, // Last 30 days
      ],
    };
  },

  computed: {
    ...mapState('user', ['timeZone', 'timeZoneOffset']),

    ...mapGetters({
      getMessagesData: 'reports/getMessagesData',
      getMessagesPerType: 'reports/getMessagesPerType',
      getReportsTable: 'reports/getReportsTable',
    }),

    ...mapState({
      filters: (state) => state.reports.filters,
      loading: (state) => state.reports.loading,
      userInfo: (state) => state.user.user,
    }),
  },

  created() {
    this.metricOptions = this.metricSubOptions.byChannel;

    if (
      Object.keys(this.userInfo).length &&
      this.userInfo.BetaFeatures.includes('WEB_Hide_WA_Report')
    ) {
      this.reportTypeOptions = this.reportTypeOptions.filter(
        (v) => v.value !== 'whatsapp_conversations'
      );
    }

    // Set default filter values
    let reportType = 'channel_wise'; //'whatsapp_conversations';
    this.updateChannelFilter({
      reportType,
      metric: this.metricOptions[0].value,
      channels: this.channelOptions.map((c) => c.value),
      chargable: this.metricSubOptions.whatsappConversations
        .filter((o) => !o.value.match(/all/i))
        .map((c) => c.value),
      daterange: this.defaultDaterange,
    });

    if (
      Object.keys(this.userInfo).length &&
      this.userInfo.RegionId &&
      `${this.userInfo.RegionId}` === '1' &&
      !this.userInfo.BetaFeatures.includes('WEB_Hide_WA_Report')
    ) {
      reportType = 'whatsapp_conversations';
    }
    this.updateFilter({ reportType });

    this.loadSelectOptions();
  },

  methods: {
    ...mapMutations({
      setFilters: 'reports/SET_FILTERS',
    }),

    ...mapActions({
      getReportsData: 'reports/getReportsData',
      exportReportsData: 'reports/exportReportsData',
    }),

    async getDownloadReports(payload = {}) {
      try {
        await this.exportReportsData(payload);
        this.$notify({
          title: this.$t('chat_apps.reports.index[2]'),
          message: this.$t('chat_apps.reports.index[3]'),
          type: 'success',
          duration: 8000,
        });
        this.showExportModal = false;
      } catch (err) {
        this.$showError(this, err);
        // this.$message.error('Oops, failed to export logs');
      }
    },

    async loadSelectOptions() {
      try {
        const { channels, chargable, ...payload } = this.filters;

        await this.getReportsData(payload);
      } catch (err) {
        this.$showError(this, err);
        // this.$message.error('Failed to load filter options. Please try again.');
      }
    },

    displayExportReports(bool = false) {
      this.showExportModal = bool;
    },

    // Fetch reports data based on filters
    getData() {
      const { channels, chargable, ...payload } = this.filters;

      this.getReportsData(payload);
    },

    updateChannelFilter(filter = {}) {
      this.setFilters({
        filters: {
          ...this.filters,
          ...filter,
          offset: this.timeZoneOffset,
        },
      });
    },

    // Update filter
    updateFilter(filter = {}) {
      let f = filter;

      // If updated filter is Report type; change metric options based on type
      if (
        Object.keys(filter).includes('reportType') &&
        filter.reportType === 'whatsapp_conversations'
      ) {
        this.metricOptions = this.metricSubOptions.whatsappConversations;
        f = { ...f, metric: this.metricOptions[0].value };
      } else if (
        Object.keys(filter).includes('reportType') &&
        filter.reportType === 'channel_wise'
      ) {
        this.metricOptions = this.metricSubOptions.byChannel;
        f = { ...f, metric: this.metricOptions[0].value };
      }

      // If updated filter is Metric
      if (
        Object.keys(filter).includes('metric') &&
        this.filters.reportType.match(/whatsapp_conversations/i) &&
        !filter.metric.match(/all/i)
      ) {
        f = { ...f, chargable: [filter.metric] };
      } else if (this.filters.reportType.match(/whatsapp_conversations/i)) {
        const chargable = this.metricSubOptions.whatsappConversations
          .filter((o) => !o.value.match(/all/i))
          .map((c) => c.value);
        f = { ...f, chargable };
      }

      this.setFilters({
        filters: {
          ...this.filters,
          ...f,
        },
      });

      this.loadSelectOptions();

      // this.getData();
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
