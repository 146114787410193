import Moment from 'moment';
import http from '../../../utils/http';

export default {
  // Fetch logs data
  getLogs: async ({ commit, state, rootState }, payload = {}) => {
    if (!payload.subAccountId) return;

    commit({ type: 'SET_LOADING', bool: true });
    const { daterange, ...f } = payload;
    const offset = payload.offset || 0;

    const selectedDate = {
      from: Moment(daterange[0]).format('YYYY-MM-DD'),
      to: Moment(daterange[1]).format('YYYY-MM-DD'),
    };

    // convert: YYYY-MM-DD -> user set timezone -> unix timestamp
    const dateFrom = Moment.utc(selectedDate.from).utc(true).utcOffset(offset, true).startOf('day');
    const dateTo = Moment(selectedDate.to).utc(true).utcOffset(offset, true).endOf('day');

    const dates = [dateFrom.valueOf(), dateTo.valueOf()];

    const p = {
      // eslint-disable-next-line
      showMessageBody: rootState.user.showMessageBody | 0, // turn Boolean to Int(0 or 1)
      daterange: dates,
      ...f,
      offset: 0,
    };
    const query = Object.keys(p)
      .map((key) => `${key}=${p[key]}`)
      .join('&');
    try {
      const { data } = await http.get(`/chats/logs?${query}`);
      if (data.logs.length) {
        commit({ type: 'SET_LOGS', logs: data });
        if (state.filters.dir === 1) {
          commit({
            type: 'SET_PAGE',
            pageInfo: {
              index: state.pageInfo.index + 1,
              end: false,
            },
          });
        } else if (state.filters.dir === -1) {
          commit({
            type: 'SET_PAGE',
            pageInfo: {
              index: state.pageInfo.index - 1,
              end: false,
            },
          });
        }
      } else {
        // Check if end of page
        commit({
          type: 'SET_PAGE',
          pageInfo: {
            index: state.pageInfo.index,
            end: true,
          },
        });
      }

      return { success: true };
    } catch (err) {
      commit('API_ERROR', err, { root: true });
      return Promise.reject(err);
    } finally {
      commit({ type: 'SET_LOADING', bool: false });
    }
  },

  // Fetch status list
  getStatusList: async ({ commit }) => {
    try {
      const { data } = await http.get('/chats/logs/statuses');
      commit({ type: 'SET_STATUS_LIST', list: data });
    } catch (err) {
      commit('API_ERROR', err, { root: true });
      return Promise.reject(err);
    }
  },

  // Export logs data
  exportLogsData: async ({ commit }, payload) => {
    // Convert date to unix
    const { daterange, ...q } = payload;
    const daterangeFormatted = daterange.map((dt) => Moment(dt).format('YYYY-MM-DD'));
    const dr = daterangeFormatted.map((d) => Moment.utc(d).unix());
    const p = { daterange: dr, ...q };

    const query = Object.keys(p)
      .map((key) => `${key}=${p[key]}`)
      .join('&');

    try {
      await http.get(`/chats/logs/export?${query}`);
    } catch (err) {
      commit('API_ERROR', err, { root: true });
      return Promise.reject(err);
    }
  },
};
