<template>
  <div class="whatsapp-form">
    <!-- Step 1 -->
    <el-form
      v-show="step <= 1"
      ref="form1"
      :model="form1"
      :rules="form1Rules"
      class="leading-none"
      hide-required-asterisk
    >
      <el-form-item prop="name">
        <slot name="label">
          <p class="text-sm text-grey-darkest leading-none">
            {{ $t('column_labels.channel_name') }}
          </p>
          <el-input
            v-model="form1.name"
            :placeholder="$t('fields.account_name_placeholder')"
            size="small"
            maxlength="35"
            show-word-limit
          />
        </slot>
      </el-form-item>

      <hr class="w-1/2 h-0 border-t border-grey-dark my-8 ml-0" />

      <el-form-item prop="phone">
        <slot name="label">
          <p class="text-sm text-grey-darkest leading-none">
            {{ $t('column_labels.phone_no') }}
          </p>
          <div class="relative">
            <vue-tel-input
              v-model="form1.phone"
              required
              :input-options="{ showDialCode: true }"
              class="whatsapp-form__tel-input"
              @input="handlePhoneValidate"
              @blur="handleOnBlurPhone"
              @country-changed="countryCodeUpdate"
            />
          </div>
        </slot>
      </el-form-item>

      <el-form-item prop="accountName">
        <slot name="label">
          <p class="text-sm text-grey-darkest leading-none">
            {{ $t('fields.business_name') }}
          </p>
          <el-input
            v-model="form1.accountName"
            placeholder="Ex. Acme Corp SG"
            size="small"
            maxlength="50"
            show-word-limit
          />
        </slot>
      </el-form-item>

      <el-form-item prop="category">
        <slot name="label">
          <p class="text-sm text-grey-darkest leading-none">
            {{ $t('column_labels.category') }}
          </p>
          <el-select
            v-model="form1.category"
            :placeholder="$t('actions.select_category')"
            filterable
            @change="changeWACategory"
          >
            <el-option
              v-for="c in categories"
              :key="c.category"
              :label="c.name"
              :value="c.category"
            >
            </el-option>
          </el-select>
        </slot>
      </el-form-item>

      <el-form-item prop="tagline">
        <slot name="label">
          <p class="text-sm text-grey-darkest leading-none">
            {{ $t('fields.tagline') }}
          </p>
          <el-input
            v-model="form1.tagline"
            :placeholder="$t('fields.business_tagline_placeholder')"
            maxlength="130"
            show-word-limit
          />
        </slot>
      </el-form-item>

      <el-form-item prop="description">
        <slot name="label">
          <p class="text-sm text-grey-darkest leading-none">
            {{ $t('column_labels.description') }}
          </p>
          <el-input
            v-model="form1.description"
            type="textarea"
            :rows="4"
            :placeholder="$t('fields.business_description_placeholder')"
            maxlength="250"
            show-word-limit
          />
        </slot>
      </el-form-item>

      <el-form-item class="mt-10">
        <el-button type="primary" size="small" @click="next">
          {{ $t('actions.next') }}
        </el-button>
        <el-button size="small" @click="cancel">
          {{ $t('actions.cancel') }}
        </el-button>
      </el-form-item>
    </el-form>

    <!-- Step 2 -->
    <el-form
      v-show="step >= 2"
      ref="form2"
      :model="form2"
      :rules="form2Rules"
      class="leading-none"
      hide-required-asterisk
    >
      <div class="flex justify-between items-center mb-10">
        <div>
          <p class="text-lg text-blue">{{ $t('chat_apps.channels.whatsapp[1]') }}</p>
          <p class="mt-2 test-sm text-grey-darker">{{ $t('chat_apps.channels.whatsapp[2]') }}!</p>
        </div>
        <p
          class="text-base text-blue flex items-center hover:text-grey-dark cursor-pointer"
          @click="back"
        >
          <i class="el-icon-back mr-2"></i>
          {{ $t('actions.back') }}
        </p>
      </div>

      <el-form-item prop="email">
        <slot name="label">
          <p class="text-sm text-grey-darkest leading-none">
            {{ $t('chat_apps.channels.whatsapp[3]') }}
          </p>

          <el-input v-model="form2.email" placeholder="Ex. contact@my-company.com" size="small" />
        </slot>
      </el-form-item>

      <el-form-item prop="websiteUrl">
        <slot name="label">
          <p class="text-sm text-grey-darkest leading-none">
            {{ $t('column_labels.website_url') }}
          </p>
          <el-input
            v-model="form2.websiteUrl"
            placeholder="https://www.my-company.com"
            size="small"
          />
        </slot>
      </el-form-item>

      <el-form-item prop="avatarUrl">
        <slot name="label">
          <p class="text-sm text-grey-darkest leading-none">
            {{ $t('column_labels.profile_pic') }}
          </p>
          <el-input
            v-model="form2.avatarUrl"
            placeholder="https://www.my-company/avatar.png"
            size="small"
          />
        </slot>
      </el-form-item>

      <el-form-item prop="street">
        <slot name="label">
          <p class="text-sm text-grey-darkest leading-none">
            {{ $t('column_labels.business_address') }}
          </p>
          <el-input
            v-model="form2.street"
            :placeholder="$t('fields.street')"
            size="small"
            maxlength="40"
            show-word-limit
          />
        </slot>
      </el-form-item>

      <el-form-item prop="city">
        <el-input
          v-model="form2.city"
          :placeholder="$t('fields.city')"
          size="small"
          maxlength="30"
          show-word-limit
        />
      </el-form-item>

      <el-form-item prop="state">
        <el-input
          v-model="form2.state"
          :placeholder="$t('fields.state')"
          size="small"
          maxlength="30"
          show-word-limit
        />
      </el-form-item>

      <div class="flex">
        <div class="w-3/5 mr-5">
          <el-form-item prop="country">
            <el-select
              v-model="form2.country"
              :placeholder="$t('fields.country')"
              class="w-full"
              size="small"
              filterable
            >
              <el-option
                v-for="country in countriesJson"
                :key="country.value"
                :label="country.name"
                :value="country.value"
              ></el-option>
            </el-select>
          </el-form-item>
        </div>

        <div class="w-2/5">
          <el-form-item prop="zipcode">
            <el-input
              v-model="form2.zipcode"
              :placeholder="$t('fields.zip')"
              size="small"
              step="0"
            />
          </el-form-item>
        </div>
      </div>

      <el-form-item prop="timezone">
        <slot name="label">
          <p class="text-sm text-grey-darkest leading-none">
            {{ $t('date_labels.timezone') }}
          </p>
          <el-select
            v-model="form2.timezone"
            :placeholder="$t('date_labels.pick_time_zone')"
            filterable
          >
            <el-option
              v-for="tz in timeZones"
              :key="tz.name"
              :label="`${tz.utcOffsetStr} (${tz.name})`"
              :value="`${tz.utcOffsetStr} (${tz.name})`"
            >
            </el-option>
          </el-select>
        </slot>
      </el-form-item>

      <el-form-item class="mt-10">
        <el-button type="primary" size="small" @click="submit">
          {{ $t('chat_apps.templates.create[26]') }}
        </el-button>
        <el-button size="small" @click="cancel">
          {{ $t('actions.cancel') }}
        </el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import countriesJson from '@/json/countries.json';
import ct from 'countries-and-timezones';
import { reject } from 'async';

export default {
  name: 'WhatsappForm',

  props: {
    channel: {
      default: () => {},
      type: Object,
    },
  },

  data() {
    const validatePhone = (rule, value, callback) => {
      if (this.validPhone && value.trim().length) {
        callback();
      } else {
        callback(new Error('Invalid phone'));
      }
    };

    const checkImage = async (rule, value, callback) => {
      const isValid = await new Promise((resolve) => {
        const img = new Image();

        img.src = value;

        img.onload = () => resolve(true);
        img.onerror = () => resolve(false);
      });

      if (isValid) callback();
      else {
        this.isLoading = false;
        callback(new Error('Image does not exist'));
      }
    };

    const self = this;

    return {
      countriesJson,
      validPhone: false,
      step: 1,
      filled: 1,
      categories: [
        {
          category: 'AUTHENTICATION',
          waCategory: 'AUTHENTICATION',
          name: this.$t('chat_apps.channels.waes.categories[17]'),
        },
        {
          category: 'MARKETING',
          waCategory: 'MARKETING',
          name: this.$t('chat_apps.channels.waes.categories[18]'),
        },
        {
          category: 'UTILITY',
          waCategory: 'UTILITY',
          name: this.$t('chat_apps.channels.waes.categories[19]'),
        },
      ],
      timeZones: null,

      form1: {
        channel: this.channel.code,
        name: '',
        fbBusinessManagerId: '',
        phone: '',
        phoneNational: '',
        countryCode: '',
        accountName: '',
        category: 'AUTHENTICATION',
        waCategory: 'AUTHENTICATION',
        tagline: '',
        description: '',
      },

      form2: {
        email: '',
        websiteUrl: '',
        avatarUrl: '',
        street: '',
        city: '',
        state: '',
        country: '',
        zipcode: '',
        timezone: 'UTC+00 (UTC)',
      },

      form1Rules: {
        channel: [
          {
            required: true,
            message: self.$t('validations.required', {
              value: self.$tc('column_labels.channel', 1),
            }),
            trigger: 'blur',
          },
        ],

        name: [
          {
            required: true,
            message: self.$t('validations.required', {
              value: self.$t('column_labels.channel_name'),
            }),
            trigger: 'blur',
          },
        ],

        fbBusinessManagerId: [
          {
            required: true,
            message: self.$t('chat_apps.channels.whatsapp[4]'),
            trigger: 'blur',
          },
        ],

        phone: [
          {
            validator: validatePhone,
            trigger: 'blur',
          },
        ],

        accountName: [
          {
            required: true,
            message: self.$t('validations.required', { value: self.$t('fields.business_name') }),
            trigger: 'blur',
          },
        ],

        category: [
          {
            required: true,
            message: self.$t('validations.required', { value: self.$t('column_labels.category') }),
            trigger: 'blur',
          },
        ],

        tagline: [
          {
            required: true,
            message: self.$t('validations.required', { value: self.$t('fields.tagline') }),
            trigger: 'blur',
          },
        ],

        description: [
          {
            required: true,
            message: self.$t('validations.required', {
              value: self.$t('column_labels.description'),
            }),
            trigger: 'blur',
          },
        ],
      },

      form2Rules: {
        email: [
          {
            required: true,
            message: self.$t('validations.required', { value: self.$t('fields.email') }),
            trigger: 'blur',
          },
          {
            type: 'email',
            message: self.$t('validations.valid', { value: self.$t('fields.email') }),
            trigger: ['blur', 'change'],
          },
        ],

        websiteUrl: [
          {
            required: true,
            message: self.$t('validations.required', {
              value: self.$t('column_labels.website_url'),
            }),
            trigger: 'blur',
          },
          {
            pattern:
              /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)$/,
            message: self.$t('validations.valid', { value: self.$t('column_labels.website_url') }),
            trigger: 'blur',
          },
        ],

        avatarUrl: [
          {
            required: true,
            message: self.$t('validations.required', { value: self.$t('fields.avatar_url') }),
            trigger: 'blur',
          },
          {
            pattern: /(?:([^:/?#]+):)?(?:\/\/([^/?#]*))?([^?#]*\.(?:jpe?g|png)$)/,
            message: self.$t('validations.valid', { value: self.$t('fields.avatar_url') }),
            trigger: 'blur',
          },
          {
            validator: checkImage,
            message: self.$t('validations.image_does_not_exist'),
            trigger: 'blur',
          },
        ],

        street: [
          {
            required: true,
            message: self.$t('validations.required', {
              value: self.$t('fields.street'),
            }),
            trigger: 'blur',
          },
        ],

        city: [
          {
            required: true,
            message: self.$t('validations.required', {
              value: self.$t('fields.city'),
            }),
            trigger: 'blur',
          },
        ],

        state: [
          {
            required: true,
            message: self.$t('validations.required', {
              value: self.$t('fields.state'),
            }),
            trigger: 'blur',
          },
        ],

        address1: [
          {
            required: true,
            message: self.$t('validations.required', {
              value: self.$t('fields.address_line', { no: '1' }),
            }),
            trigger: 'blur',
          },
        ],

        address2: [
          {
            required: true,
            message: self.$t('validations.required', {
              value: self.$t('fields.address_line', { no: '2' }),
            }),
            trigger: 'blur',
          },
        ],

        country: [
          {
            required: true,
            message: self.$t('validations.required', { value: self.$t('fields.country') }),
            trigger: 'blur',
          },
        ],

        zipcode: [
          {
            required: true,
            message: self.$t('validations.required', { value: self.$t('fields.zip') }),
            trigger: 'blur',
          },
        ],

        timezone: [
          {
            required: true,
            message: self.$t('validations.required', { value: self.$t('date_labels.timezone') }),
            trigger: 'blur',
          },
        ],
      },
    };
  },

  updated() {
    const allTz = ct.getAllTimezones();

    if (!this.timeZones) {
      this.timeZones = Object.values(allTz).sort((a, b) => a.utcOffset - b.utcOffset);
    }
  },

  methods: {
    countryCodeUpdate(obj) {
      const { iso2, dialCode } = obj;
      this.form2.country = this.filled === 1 ? iso2 : this.form2.country;
      this.form1.countryCode = dialCode;
    },

    handlePhoneValidate(n, validator) {
      this.validPhone = validator.isValid;
      if (validator.isValid) {
        this.form1.phoneNational = validator.number.significant;
      }
      this.$refs.form1.validateField('phone');
    },

    handleOnBlurPhone() {
      this.$refs.form1.validateField('phone');
    },

    cancel() {
      this.$parent.$emit('close');
    },

    next() {
      if (this.$refs.form1) {
        this.$refs.form1.validate((valid) => {
          if (valid) {
            this.step += 1;
            this.filled += 1;
          } else {
            return false;
          }
          return false;
        });
      }
    },

    changeWACategory(category) {
      const cat = this.categories.find((c) => c.category === category);

      this.form1.waCategory = cat.waCategory;
    },

    back() {
      this.step -= 1;
    },

    submit() {
      if (this.$refs.form2) {
        this.$refs.form2.validate((valid) => {
          if (valid) {
            this.$emit('submit', { ...this.form1, ...this.form2 });
          } else {
            return false;
          }
          return false;
        });
      }
    },
  },
};
</script>

<styles lang="scss" scoped>
.whatsapp-form {
  .el-form-item.is-error {
    .whatsapp-form__tel-input {
      border: solid 1px tomato !important;
    }
  }
  .el-form-item.is-success {
    .whatsapp-form__tel-input {
      border: solid 1px #67C23A !important;
    }
  }
  .el-form-item__label {
    line-height: 0;
    margin: 20px 0 10px;
  }
  .note {
    word-break: break-word;
  }
  &__tel-input {
    margin-top: 10px;
    height: 32px;
    border-radius: 4px !important;
    border: 1px solid #DCDFE6 !important;
    box-shadow: none !important;

    .vti__selection {
      height: 30px;
    }

    input {
      font-size: 13px;
      color: #606266;
      border-radius: 4px !important;

      &::placeholder {
        color: #E0E2E5;
        opacity: 1;
      }
    }
  }
}
</styles>
