<template>
  <div class="mt-8">
    <p class="text-sm text-grey-darker w-full mt-8">
      {{ $t('chat_apps.register.facebook_setup.select_phone') }}
    </p>
    <el-row class="mt-1">
      <el-col :offset="3" :span="8">
        <div
          class="channel-card bg-white shadow w-full block p-3 text-center rounded mt-2"
          :disabled="phoneDetails && phoneDetails.length"
          @click="launchWhatsAppSignup()"
        >
          <img :src="getIcon(channelCode)" width="25" height="25px" alt="channel-icon" />
          <p class="mt-2 text-xs text-black">
            {{ $t('chat_apps.channels.waes.title') }}
          </p>
          <p class="text-xs text-blue mt-2">
            {{ $t('chat_apps.channels.card[2]') }}
          </p>
        </div>
      </el-col>
    </el-row>
    <el-row>
      <el-col :offset="3" :span="8">
        <div v-if="phoneDetails && phoneDetails.length" class="pt-8 mt-4 w-full">
          <p class="text-sm text-grey-darker mb-2">
            {{ $t('chat_apps.channels.waes.select_phone') }}
          </p>
          <el-select
            v-model="selectedPhoneNumberId"
            class="w-full"
            :placeholder="$t('chat_apps.channels.waes.select_phone')"
            size="small"
          >
            <el-option
              v-for="p in phoneDetails"
              :key="p.id"
              :label="getPhoneLabel(p)"
              :value="p.id"
            >
            </el-option>
          </el-select>
        </div>
        <p
          v-if="setupData.facebookSetup.processRetry || setupData.facebookSetup.submitRetry"
          class="text-red pt-6 pb-3 text-sm"
        >
          {{ $t('chat_apps.register.facebook_setup.retry_message') }}
        </p>
        <p v-if="errMessage" class="text-red text-sm">
          {{ errMessage }}
        </p>
      </el-col>
    </el-row>
    <p class="pb-12 pt-8">&nbsp;</p>
  </div>
</template>
<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import whatsAppIcon from '@/assets/channels/whatsapp.svg';
import Async from 'async';

export default {
  name: 'FacebookSetup',

  props: {
    setupData: {
      type: Object,
      default: () => {},
    },

    user: {
      type: Object,
      default: () => {},
    },

    update: {
      type: Function,
      default: () => {},
    },

    setLoading: {
      type: Function,
      default: () => {},
    },

    triggerRetry: {
      type: Boolean,
      default: false,
    },

    submit: {
      type: Function,
      default: () => {},
    },

    errMessage: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      selectedPhoneNumberId: null,
      channelCode: 'WA',
      accessToken: null,
      wabaIds: [],
    };
  },

  computed: {
    ...mapState('channels', ['caSystemUsers', 'selectedWabaId', 'productMeta', 'phoneDetails']),
  },

  watch: {
    selectedPhoneNumberId: {
      handler(val) {
        if (val) {
          const phone = this.phoneDetails.find((p) => p.id === this.selectedPhoneNumberId);

          this.SET_SELECTED_WABA_ID({ data: phone.wabaId });
          this.selectedPhoneNumber = phone.phoneNumber || null;

          // trigger processing
          this.processWABA();
        }
      },
    },

    triggerRetry: {
      immediate: true,
      handler(val) {
        if (val) {
          if (val) this.processWABA();
        }
      },
    },
  },

  async created() {
    window.fbAsyncInit = () => {
      // JavaScript SDK configuration and setup
      // eslint-disable-next-line
      FB.init({
        appId: process.env.VUE_APP_FB_WHATSAPP_APP_ID, // Facebook App ID
        cookie: true, // enable cookies
        xfbml: true, // parse social plugins on this page
        version: 'v19.0', // GAPI version
      });
    };

    // Load the JavaScript SDK asynchronously
    ((d, s, id) => {
      let fjs = d.getElementsByTagName(s)[0]; // eslint-disable-line
      if (d.getElementById(id)) return;
      const js = d.createElement(s);
      js.id = id;
      js.src = 'https://connect.facebook.net/en_US/sdk.js';
      fjs.parentNode.insertBefore(js, fjs);
    })(document, 'script', 'facebook-jssdk');
  },

  mounted() {
    const sessionInfoListener = (event) => {
      if (
        event.origin !== 'https://www.facebook.com' &&
        event.origin !== 'https://web.facebook.com'
      ) {
        return;
      }

      try {
        const data = JSON.parse(event.data);
        if (data.type === 'WA_EMBEDDED_SIGNUP') {
          const { version, event: statusType } = data;
          // if user finishes the Embedded Signup flow
          if (data.event === 'FINISH') {
            const { phone_number_id: phoneNumberId, waba_id: wabaId } = data.data;

            const { track } = this.$telemetry;

            track('whatsapp_signup_completed', {
              uiArea: 'wa_embedded_signup_page',
              phoneNumberId,
              wabaId,
              version,
              statusType,
            });
          }
          // if user cancels the Embedded Signup flow
          else {
            const { current_step: currentStep } = data.data;
            track('whatsapp_signup_canceled', {
              uiArea: 'wa_embedded_signup_page',
              currentStep,
              version,
              statusType,
            });
          }
        }
      } catch (e) {
        // Don’t parse info that’s not a JSON
        console.log('Non JSON Response', event.data);
      }
    };

    window.addEventListener('message', sessionInfoListener);
  },

  beforeDestroy() {
    window.removeEventListener('message');
  },

  methods: {
    ...mapActions({
      getPhoneDetails: 'channels/getPhoneDetails',
      getSharedWABAId: 'channels/getSharedWABAId',
      processIntegration: 'channels/processIntegration',
    }),
    ...mapMutations('channels', ['SET_WABA_IDS', 'SET_SELECTED_WABA_ID']),

    getIcon(code) {
      let icon = '';
      switch (code) {
        case 'WA':
          icon = whatsAppIcon;
          break;
        default:
          break;
      }
      return `${process.env.VUE_APP_BASE_URL.replace(/\/$/g, '')}${icon}`;
    },

    getPhoneLabel(p) {
      return `${p.verifiedName} (${p.phoneNumber})`;
    },

    showErrorMessage(msg, details) {
      if (window.Bugsnag) {
        const bugSnag = window.Bugsnag;

        bugSnag.notify(new Error(msg), (event) => {
          event.severity = 'error'; // eslint-disable-line
          event.addMetadata('details', details); // eslint-disable-line
          event.addMetadata('user', this.user); // eslint-disable-line
          event.addMetadata('setupData', this.setupData); // eslint-disable-line
        });
      }

      this.$message.error(this.$t('chat_apps.channels.waes.set_account_error'));
      console.log('An error occurred during the WhatsApp channel setup.'); // eslint-disable-line
    },

    async processWABA() {
      this.$emit('update', {
        facebookSetup: {
          selectedWabaId: this.selectedWabaId,
          selectedPhoneNumberId: this.selectedPhoneNumberId,
          wabaSetupDone: false,
          processRetry: false, // For when processIntegration() returns an error
          triggerRetry: false, // Retry button to show
          submitRetry: false, // For when creating a CA subaccount returns an error
          submitDone: false,
          errMessage: null,
        },
      });
      this.$emit('set-loading', true);

      try {
        await this.processIntegration();

        this.$emit('update', {
          facebookSetup: {
            selectedWabaId: this.selectedWabaId,
            selectedPhoneNumberId: this.selectedPhoneNumberId,
            wabaSetupDone: true,
            processRetry: false,
            triggerRetry: false,
            submitRetry: false,
            submitDone: false,
            errMessage: null,
          },
        });

        // This triggers the creation of the CA subaccount
        this.$emit('submit');
      } catch (err) {
        const { data } = err;
        const errorMessage = (data && data.error) || 'Error processing WABA ID';
        const details = {
          wabaIds: this.wabaIds,
          selectedWabaId: this.selectedWabaId,
          productMeta: this.productMeta,
        };

        this.showErrorMessage('Error processing WABA ID', details);
        this.$emit('set-loading', false);
        this.$emit('update', {
          facebookSetup: {
            selectedWabaId: this.selectedWabaId,
            selectedPhoneNumberId: this.selectedPhoneNumberId,
            wabaSetupDone: false,
            processRetry: true,
            triggerRetry: false,
            submitRetry: false,
            submitDone: false,
            errMessage: errorMessage,
          },
        });
      }
    },

    async getWhatsAppAccountDetails() {
      try {
        const { accessToken } = this;

        this.$emit('set-loading', true);

        await this.getSharedWABAId({ accessToken });

        const phone = this.phoneDetails;

        this.$emit('set-loading', false);

        // Auto select wabaID if only one
        if (phone && phone.length === 1) {
          this.selectedPhoneNumberId = phone[0].id; // will trigger setting of wabaId
        }
      } catch (err) {
        const { data } = err;
        const errorMessage =
          (data && data.error) || 'Error fetching data from Facebook after embedded signup.';
        if (window.Bugsnag) {
          const bugSnag = window.Bugsnag;

          bugSnag.notify(new Error(errorMessage), (event) => {
            event.severity = 'error';
            event.addMetadata('response', response);
            event.addMetadata('user', this.user);
          });
        }

        this.$message.error('An error occurred during the WhatsApp channel setup.');
      }
    },

    async launchWhatsAppSignup() {
      const {
        businessDetails: { phoneNational, countryCode, accountName, waCategory, description },

        contactInfo: { email, websiteUrl, street, city, state, country, zipcode, timezone },
      } = this.setupData;

      let tZone = 'UTC+00';
      if (timezone) {
        const [tz] = timezone.split(' ');
        tZone = `UTC${tz}`;
      }

      const channelDetails = {
        business: {
          name: accountName,
          email,
          phone: {
            code: countryCode,
            number: phoneNational,
          },
          website: websiteUrl,
          address: {
            streetAddress1: street,
            city,
            state,
            zipPostal: zipcode,
            country,
          },
          timezone: tZone,
        },
        phone: {
          displayName: accountName,
          category: waCategory,
          description: description || '',
          code: countryCode,
          number: phoneNational,
        },
      };
      // Conversion tracking code
      // eslint-disable-next-line
      window.fbq && window.fbq('trackCustom', 'PrepaidCAStart', {
          appId: process.env.VUE_APP_FB_WHATSAPP_APP_ID,
          feature: 'whatsapp_embedded_signup',
        });

      // Launch Facebook login
      // eslint-disable-next-line
      FB.login((response) => {
          if (response.authResponse) {
          this.accessToken = response.authResponse.accessToken; // eslint-disable-line

            // Use this token to call the debug_token API and get the shared WABA's ID
            this.getWhatsAppAccountDetails();
          } else {
            if (window.Bugsnag) {
              const bugSnag = window.Bugsnag;

              bugSnag.notify(
                new Error('User cancelled WhatsApp embedded signup or did not fully authorize.'),
                (event) => {
              event.severity = 'warning'; // eslint-disable-line
              event.addMetadata('response', response); // eslint-disable-line
              event.addMetadata('user', this.user); // eslint-disable-line
                }
              );
            }

          this.$message.error('User cancelled WhatsApp channel setup or did not fully authorize.'); // eslint-disable-line
          console.log('User cancelled WhatsApp embedded signup or did not fully authorize.'); // eslint-disable-line
          }
        },
        {
          scope: 'business_management,whatsapp_business_management',
          extras: {
            feature: 'whatsapp_embedded_signup',
            setup: {
              business: channelDetails.business,
              phone: channelDetails.phone,
            },
          },
        }
      );
    },
  },
};
</script>
<style lang="scss" scoped>
.channel-card {
  cursor: pointer;
  transition: all 0.05s ease-in;
  border: solid 1px transparent;

  &:hover {
    border-color: #3490dc;
    transform: scale(1.03);
    transform-origin: center;
  }

  &__disabled {
    cursor: default;

    &:hover {
      border-color: #dae1e7;
      opacity: 0.7;
      transform: none;
      filter: grayscale(100%);
    }
  }
}
</style>
